.Toastify__toast--error {
  border: 1px solid #26292c !important;
  border-radius: 5px !important;
  background: #393e41 !important;
}
.Toastify__toast--error::after {
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
.Toastify__toast--error::before {
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--success {
  border: 1px solid #26292c !important;
  border-radius: 5px !important;
  background: #393e41 !important;
}
.Toastify__toast--success::before {
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--success::after {
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
.Toastify__toast--warning {
  border: 1px solid #26292c !important;
  border-radius: 5px !important;
  background: #393e41 !important;
}
.Toastify__toast--warning::before {
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--warning::after {
  position: absolute;
  color: #f6f7eb;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
.Toastify__toast-body {
  font-family: 'Century Gothic';
  color: #f6f7eb;
  font-size: 14.5px;
  padding-left: 20px;
  line-height: 20px;
  padding: 0px;
  padding-top: 25px;
  width: 100%;
  font-weight: 400;
  margin-left: 15px !important;
}
.Toastify__toast > button > svg {
  display: none;
}

:root {
  --jet: #26292C;
  --onyx: #393e41;
  --cinnabar: #e94f37;
  --tomato: #CA2E16;
  --ivory: #f6f7eb;
  --beige: #E4E7C5;
  --gold: #AB8A5E;
  --blue: #063472;
  --darkblue: #0C354B;
  --white: #f0f0f0;
  --pink: #e24589;
  --darkpink: #de2b79;
  --gray: #e3e7eb;
  --darkgray: #dbe0e6;
  --lightgreen: #d2e6d3;
  --green: #5dceb0;
  --darkgreen: #43c7a3;
  --softdark: #242627;
  --dark: #141515;
}

@import './Constants.css';

.login_page_container {
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: var(--cinnabar);
}

html {
  font-family: 'Century Gothic';
}

.logo {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  font-size: 70px;
  margin: 0;
  writing-mode: vertical-lr;
  text-align: center;
  line-height: 1.1;
  color: var(--ivory);
}

.rotate {
  transform: rotate(180deg);
}

.logo_wrapper {
  background-color: var(--cinnabar);
  display: grid;
  height: 350px;
  justify-content: center;
  align-content: center;
  grid-template-columns: max-content max-content;
  margin-top: 50px;
}

.text {
  font-size: 16px;
  font-weight: bold;
  color: var(--ivory);
}

.system_logo {
  margin-top: 10px;
  height: 100px;
}

.system_logo_txt {
  height: 20px;
}

.trialment_login_logo {
  filter: invert(90%) sepia(3%) saturate(2321%) hue-rotate(169deg)
    brightness(90%) contrast(84%);
}

.cota_login_logo {
  filter: invert(46%) sepia(14%) saturate(1096%) hue-rotate(355deg)
    brightness(102%) contrast(86%);
}

.trialment_login_form {
  margin-top: 5px;
  color: var(--jet);
  font-weight: bold;
  font-size: 18px;
}

.trialment_login_form_row {
  padding-top: 10px;
  width: fit-content;
}

.button_margin {
  margin-left: 12px;
}

.forgot_password_txt {
  font-size: 14px;
  padding-top: 5px;
  text-decoration: underline;
}

input[type='text'],
textarea {
  height: 25px;
  width: 330px;
  padding: 0.5rem;
  font-size: 1rem;
  border: 2px solid var(--jet);
  border-radius: 4px;
}

input[type='password'],
textarea {
  height: 25px;
  width: 330px;
  border: 2px solid var(--jet);
  padding: 0.5rem;
  font-size: 1rem;
  border: 2px solid black;
  border-radius: 4px;
}

input[type='password_view'],
textarea {
  height: 25px;
  width: 330px;
  border: 2px solid var(--jet);
  padding: 0.5rem;
  font-size: 1rem;
  border: 2px solid black;
  border-radius: 4px;
}

#dice-box,
#dice-box canvas {
  position: fixed;
  pointer-events: none;
  z-index: 900;
  width: calc(100% + 8px);
  height: calc(100% + 8px);;
  top: -8px;
  left: -8px;
}

.buttonList {
  display: grid;
  grid-template-columns: auto auto 1fr;
  column-gap: 5px;
  row-gap: 10px;
}
.header {
  font-weight: bold;
  font-size: 120%;
  text-decoration: underline;
}
.notation {
  font-weight: bold;
}

/* ----------------------------- */
/* Default layout for Tablet (T) */
/* ----------------------------- */

/* PART 1 LAYOUT */
.cota_character_sheet {
  display: block;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  padding-top: 80px;
  overflow-x: auto;
  background-color: var(--ivory);
  overflow-y: auto;
}
.cota_character_sheet_dark {
  background-color: var(--jet);
}
.cota_character_sheet_left,
.cota_character_sheet_right {
  width: 100%;
}
.cota_character_sheet_left_whole {
  text-align: right;
  text-align: -webkit-right;
  text-align: -moz-right;
  text-align-last: right;
  padding: 10px;
  padding-bottom: 5px;
  height: auto;
}
.cota_character_sheet_right_up {
  height: calc(40% - 15px);
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  height: auto;
}
.cota_character_sheet_right_down {
  height: calc(60% - 15px);
  height: auto;
  padding: 10px;
  padding-top: 5px;
}
.cota_decorative_outline {
  outline: 2px solid var(--onyx);
  outline-offset: -2px;
}
.cota_outline {
  outline: solid var(--jet);
  outline-width: clamp(1px, 0.8vw, 9px);
  outline-offset: -0.7vw;
  outline-offset: clamp(-9px, -0.7vw, 1px);
}
.cota_outline_dark {
  outline: solid var(--ivory);
  outline-width: clamp(1px, 0.8vw, 9px);
  outline-offset: -0.7vw;
  outline-offset: clamp(-9px, -0.7vw, 1px);
}
.cota_character_page_1 {
  z-index: 1;
  text-align-last: center;
  width: 100%;
  height: 100%;
  position: relative;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.cota_character_page_2 {
  width: 100%;
  height: 100%;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 45vw;
}
.cota_character_page_3 {
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  height: auto;
  aspect-ratio: 1300 / 800;
}

/* PART 2 STATS */
.cota_stats {
  position: relative;
  display: inline-block;
  max-width: calc(100% - 40px);
  max-height: 100%;
  aspect-ratio: 1 / 1;
}
.cota_stats_spacer {
  height: 20px;
}
.cota_header_container {
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center if needed */
  position: relative;
  width: 100%;
  height: calc(15% - 30px);
  max-height: 155px;
}
.cota_header {
  width: 100%;
  height: 12vw;
  position: relative;
  max-height: 155px;
}
.cota_stats_container {
  position: relative;
  width: 100%;
  height: calc(85% - 30px);
}
.cota_stats_level_label {
  top: 5%;
  left: 21px;
  width: 20%;
  height: 30%;
}
.cota_stats_level {
  top: 40%;
  left: 20px;
  width: 20%;
  height: 60%;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.cota_stats_identity {
  position: absolute;
  color: var(--jet);
  outline: solid var(--jet);
  top: 5%;
  left: calc(20% + 30px);
  width: calc(60% - 60px);
  height: 95%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  outline-width: clamp(1px, 0.8vw, 9px);
  outline-offset: clamp(-9px, -0.7vw, 1px);
}
.cota_stats_identity_dark {
  color: var(--ivory);
  outline: solid var(--ivory);
  outline-width: clamp(1px, 0.8vw, 9px);
  outline-offset: clamp(-9px, -0.7vw, 1px);
}
.cota_img_container {
  position: absolute;
  top: 15%;
  width: 19%;
  height: 70%;
  left: 1.5%;
}
.cota_img {
  filter: invert(46%) sepia(14%) saturate(1096%) hue-rotate(355deg)
    brightness(102%) contrast(86%);
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 1 / 1;
}
.cota_stats_character_name {
  position: absolute;
  cursor: pointer;
  top: 15%;
  left: 20%;
  width: 60%;
  height: 35%;
  font-family: 'Century Gothic';
  font-size: clamp(1px, 2.4vw, 28.5px);
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.cota_stats_character_title {
  position: absolute;
  cursor: pointer;
  bottom: 15%;
  left: 20%;
  width: 60%;
  height: 35%;
  font-family: 'Century Gothic';
  font-size: clamp(1px, 2.4vw, 28.5px);
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.cota_house_img_container {
  position: absolute;
  top: 15%;
  right: 1.5%;
  width: 19%;
  height: 70%;
  cursor: pointer;
}
.cota_house_img_container_dark {
  filter: brightness(2);
  position: absolute;
  top: 15%;
  right: 1.5%;
  width: 19%;
  height: 70%;
}
.cota_house_img {
  max-width: 100%;
  max-height: 100%;
}
.cota_stats_path_label {
  top: 5%;
  right: 21px;
  width: 20%;
  height: 30%;
}
.cota_stats_path {
  top: 40%;
  right: 20px;
  width: 20%;
  height: 60%;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.cota_stats_path_subpath_select {
  border-bottom-right-radius: 0px;
}
/*.cota_stats_path_subpath_enabled Down with Interactive Elements */
.cota_stats_subpath {
  position: absolute;
  cursor: pointer;
  color: var(--ivory);
  font-weight: 600;
  white-space: nowrap;
  outline: solid var(--jet);
  display: flex;
  justify-content: center;
  align-items: center;
  outline-width: clamp(1px, 0.8vw, 9px);
  outline-offset: clamp(-9px, -0.7vw, 1px);
  font-size: clamp(1px, 2.2vw, 28.5px);
  bottom: -46%;
  right: 20px;
  width: 20%;
  height: 50%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: var(--jet);
  z-index: 1;
}
.cota_stats_subpath:hover {
  color: var(--cinnabar);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.cota_stats_subpath_dark {
  color: var(--jet);
  outline: solid var(--ivory);
  outline-width: clamp(1px, 0.8vw, 9px);
  outline-offset: clamp(-9px, -0.7vw, 1px);
  background-color: var(--ivory);
}

.cota_stats_subpath_enabled {
  color: var(--ivory);
  outline: solid var(--cinnabar);
  outline-width: clamp(1px, 0.8vw, 9px);
  outline-offset: clamp(-9px, -0.7vw, 1px);
  background-color: var(--cinnabar);
}

.cota_stats_subpath_enabled:hover {
  color: var(--ivory);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.cota_stats_image {
  width: 100%;
  height: 100%;
  max-width: 5000px;
  max-height: 5000px;
}
.cota_character_image_container {
  z-index: -1;
  top: 19%;
  left: 19%;
  position: absolute;
  width: 62%;
  height: 62%;
}
.cota_character_image {
  width: 100%;
  height: 100%;
}
.cota_character_image_uploader {
  top: 18.5%;
  left: 29.7%;
  position: absolute;
  width: 40.8%;
  height: 62%;
  opacity: 0.6;
  border-radius: 50%/18%;
  color: rgba(0, 0, 0, 0);
}
::-webkit-file-upload-button {
  display: none;
}
::file-selector-button {
  display: none;
}
.cota_stats_area {
  background-color: var(--ivory);
  position: absolute;
  cursor: pointer;
  color: var(--jet);
  font-size: clamp(1px, 2.4vw, 28.5px);
  font-weight: 600;
  white-space: nowrap;
  outline: solid var(--jet);
  display: flex;
  justify-content: center;
  align-items: center;
  outline-width: clamp(1px, 0.8vw, 9px);
  outline-offset: clamp(-9px, -0.7vw, 1px);
}
.cota_stats_area:focus {
  outline: solid var(--gold);
  outline-width: clamp(1px, 0.8vw, 9px);
  outline-offset: clamp(-9px, -0.7vw, 1px);
}
.cota_stats_area_dark {
  background-color: var(--jet);
  color: var(--ivory);
  outline: solid var(--ivory);
  outline-width: clamp(1px, 0.8vw, 9px);
  outline-offset: clamp(-9px, -0.7vw, 1px);
}
.cota_stats_area_dark:focus {
  outline: solid var(--gold);
  outline-width: clamp(1px, 0.8vw, 9px);
  outline-offset: clamp(-9px, -0.7vw, 1px);
}
.cota_stats_label {
  position: absolute;
  color: var(--jet);
  font-family: 'Century Gothic';
  font-size: clamp(1px, 2.2vw, 28.5px);
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cota_stats_label_dark {
  color: var(--ivory);
}
.cota_stats_dice_roller {
  padding: 5%;
  cursor: pointer;
}
.cota_stats_dice_roller:hover {
  color: var(--gold);
}
.cota_stats_temporary_hit_points_label {
  top: 6%;
  width: 14.5%;
  height: 6%;
}
.cota_stats_temporary_hit_points {
  top: 12%;
  width: 14.5%;
  height: 6%;
}
.cota_stats_current_hit_points_label {
  top: 5%;
  left: 19.5%;
  width: 16%;
  height: 6%;
}
.cota_stats_current_hit_points {
  top: 5%;
  left: 35.5%;
  width: 14.5%;
  height: 6%;
}
.cota_stats_hit_points_maximum_label {
  top: 12%;
  left: 19.5%;
  width: 16%;
  height: 6%;
}
.cota_stats_hit_points_maximum {
  top: 12%;
  left: 35.5%;
  width: 14.5%;
  height: 6%;
}
.cota_stats_divine_influence_label {
  top: 16%;
  right: 10%;
  width: 35%;
  height: 5%;
}
.cota_stats_divine_influence_dice {
  top: 10%;
  right: 25%;
  width: 5%;
  height: 6%;
}
.cota_stats_divine_influence {
  top: 10%;
  right: 30%;
  width: 15%;
  height: 6%;
}
.cota_stats_agility_defense_label {
  bottom: 17%;
  left: 10%;
  width: 35%;
  height: 5%;
}
.cota_stats_agility_defense_dice {
  bottom: 11%;
  left: 45%;
  width: 5%;
  height: 6%;
}
.cota_stats_agility_defense {
  bottom: 11%;
  left: 30%;
  width: 15%;
  height: 6%;
}
.cota_stats_intuition_defense_label {
  bottom: 17%;
  right: 10%;
  width: 35%;
  height: 5%;
}
.cota_stats_intuition_defense_dice {
  bottom: 11%;
  right: 25%;
  width: 5%;
  height: 6%;
}
.cota_stats_intuition_defense {
  bottom: 11%;
  right: 30%;
  width: 15%;
  height: 6%;
}
.cota_stats_inventory {
  bottom: 5%;
  right: 0%;
  width: 20%;
  height: 5%;
  cursor: pointer;
}
.cota_stats_notes {
  bottom: 10%;
  right: 0%;
  width: 15%;
  height: 5%;
  cursor: pointer;
}
.cota_stats_inventory:hover {
  background-color: var(--gold);
  color: var(--ivory);
}
.cota_stats_notes:hover {
  background-color: var(--gold);
  color: var(--ivory);
}
.cota_stats_modifiers {
  bottom: 6%;
  left: 0%;
  width: 25%;
  height: 5%;
  cursor: pointer;
}
.cota_stats_modifiers:hover {
  background-color: var(--gold);
  color: var(--ivory);
}
.cota_stats_modifiers:hover {
  background-color: var(--);
  color: var(--ivory);
}
.cota_stats_rhombus {
  position: absolute;
  cursor: pointer;
  width: 4%;
  height: 4%;
}
.cota_stats_rhombus_pressed {
  background-color: var(--jet);
}
.cota_stats_rhombus_pressed_dark {
  background-color: var(--ivory);
}
.cota_stats_might_label {
  top: 33%;
  left: 6%;
  width: 22.9%;
  height: clamp(0px, 3.5vw, 50px);
}
.cota_stats_might_1 {
  top: 21.3%;
  left: 15.5%;
  transform: rotate(150deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_might_2 {
  top: 27%;
  left: 25.6%;
  transform: rotate(210deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_might_3 {
  top: 38.7%;
  left: 25.6%;
  transform: rotate(270deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_might_4 {
  top: 44.6%;
  left: 15.5%;
  transform: rotate(150deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_might_5 {
  top: 38.8%;
  left: 5.3%;
  transform: rotate(30deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_might_6 {
  top: 27%;
  left: 5.5%;
  transform: rotate(90deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_divination_label {
  top: 33%;
  right: 6%;
  width: 22.8%;
  height: 3vh;

  height: clamp(0px, 3.5vw, 50px);
}
.cota_stats_divination_1 {
  top: 21.4%;
  right: 15.5%;
  transform: rotate(150deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_divination_2 {
  top: 27.1%;
  right: 5.3%;
  transform: rotate(210deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_divination_3 {
  top: 39%;
  right: 5.3%;
  transform: rotate(270deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_divination_4 {
  top: 44.7%;
  right: 15.4%;
  transform: rotate(150deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_divination_5 {
  top: 38.9%;
  right: 25.4%;
  transform: rotate(30deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_divination_6 {
  top: 27.1%;
  right: 25.5%;
  transform: rotate(90deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_agility_label {
  bottom: 33.9%;
  left: 6%;
  width: 22.9%;
  height: clamp(0px, 3.5vw, 50px);
}
.cota_stats_agility_1 {
  bottom: 45.4%;
  left: 15.5%;
  transform: rotate(150deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_agility_2 {
  bottom: 39.7%;
  left: 25.6%;
  transform: rotate(210deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_agility_3 {
  bottom: 28%;
  left: 25.6%;
  transform: rotate(270deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_agility_4 {
  bottom: 22%;
  left: 15.4%;
  transform: rotate(150deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_agility_5 {
  bottom: 28%;
  left: 5.3%;
  transform: rotate(30deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_agility_6 {
  bottom: 39.7%;
  left: 5.5%;
  transform: rotate(90deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_intuition_label {
  bottom: 33.9%;
  right: 6%;
  width: 22.8%;
  height: clamp(0px, 3.5vw, 50px);
}
.cota_stats_intuition_1 {
  bottom: 45.3%;
  right: 15.4%;
  transform: rotate(150deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_intuition_2 {
  bottom: 39.5%;
  right: 5.2%;
  transform: rotate(210deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_intuition_3 {
  bottom: 27.7%;
  right: 5.3%;
  transform: rotate(270deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_intuition_4 {
  bottom: 22%;
  right: 15.4%;
  transform: rotate(150deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_intuition_5 {
  bottom: 27.7%;
  right: 25.4%;
  transform: rotate(30deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_stats_intuition_6 {
  bottom: 39.6%;
  right: 25.5%;
  transform: rotate(90deg) skewX(30deg) scaleY(cos(30deg));
}
.cota_rp_abilities {
  position: relative;
  display: inline-block;
  margin-left: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  aspect-ratio: 1229 / 1503;
  display: flex;
}
.cota_rp_abilities_left {
  width: 50%;
}
.cota_rp_abilities_right {
  right: 0px;
  width: 50%;
  height: 100%;
}
.cota_rp_ability_1 {
  height: calc(50% - 5px);
  border-top-left-radius: 10px;
  width: calc(100% - 5px);
}
.cota_rp_ability_2 {
  height: calc(50% - 5px);
  margin-left: 5px;
  border-top-right-radius: 10px;
  width: calc(100% - 5px);
}
.cota_rp_ability_3 {
  margin-top: 10px;
  height: calc(50% - 5px);
  border-bottom-left-radius: 10px;
  width: calc(100% - 5px);
}
.cota_consumables {
  margin-top: 10px;
  display: flex;
  height: calc(50% - 5px);
}
.cota_consumable_1 {
  height: 100%;
  width: calc(50% - 7.5px);
  margin-left: 5px;
}
.cota_consumable_2 {
  height: 100%;
  width: calc(50% - 7.5px);
  border-bottom-right-radius: 10px;
  margin-left: 10px;
}

/* PART 3 RP ABILITIES */
.cota_rp_container {
  color: var(--jet);
  height: 100%;
  font-family: 'Century Gothic';
  font-size: clamp(7.5px, 2vw - 4.3px, 20px);
  padding: calc(0.2vw - 1px);
  display: flex;
  flex-direction: column; /* Ensure children stack vertically */
}
.cota_rp_container_dark {
  color: var(--ivory);
}
.cota_rp_title {
  display: flex;
  height: 22%;
  flex: 0 1 clamp(20px, 10%, 40px); /* Allow height to flexibly grow between 20px and 40px */
}
.cota_rp_indicator {
  text-align: left;
  margin-top: 10px;
  height: calc(100% - 10px);
  width: 25%;
  margin-left: 10px;
}
.cota_rp_name {
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  margin-top: 10px;
  height: calc(100% - 10px);
  width: calc(75% - 20px);
}
.cota_rp_explainer {
  line-height: 103%;
  height: 100%;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: left;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
}
.cota_rp_actions {
  display: flex;
  height: 25%;
  flex: 0 1 clamp(20px, 10%, 40px); /* Allow height to grow between 50px and 100px */
  margin-bottom: 1.5vw;
}
.cota_rp_action_uses_title {
  text-align: left;
  margin-top: 7px;
  height: calc(100% - 10px);
  width: 20%;
  margin-left: 10px;
}
.cota_rp_action_uses {
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  margin-top: 7px;
  height: calc(100% - 14px);
  width: calc(20%);
}
.cota_rp_action_rolls_title {
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 7px;
  height: calc(100% - 10px);
  width: 20%;
  margin-left: 10px;
}
.cota_rp_action_rolls {
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  margin-top: 7px;
  height: calc(100% - 14px);
  width: calc(35% - 44px);
}
.cota_rp_action_dice {
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 7px;
  height: calc(100% - 10px);
  width: 7%;
  margin-left: 10px;
  cursor: pointer;
}
.cota_potion_name {
  margin-top: 5px;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
  text-align: left;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.cota_potion_rolls_title {
  text-align: left;
  margin-top: 7px;
  height: calc(100% - 10px);
  width: 40%;
  margin-left: 10px;
}
.cota_potion_rolls {
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  margin-top: 7px;
  height: calc(100% - 14px);
  width: calc(50% - 30px);
}
.cota_potion_dice {
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 7px;
  height: calc(100% - 10px);
  width: 10%;
  margin-left: 10px;
  cursor: pointer;
}

/* PART 4 SCROLLS */
.cota_scroll_row {
  color: var(--jet);
  height: calc(50% - 15px);
  display: flex;
  margin-left: 10px;
}
.cota_scroll_row_dark {
  color: var(--ivory);
}
.cota_scroll_slot {
  cursor: pointer;
  height: calc(100% - 10px);
  width: calc(20% - 14px);
  border-radius: 50%/7%;
  margin-left: 10px;
  font-family: 'Century Gothic';
  text-align: left;
}
.cota_scroll_stats {
  height: calc(100% - 10px);
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.cota_scroll_select {
  height: calc(100%);
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: calc(2vw - 4.3px);
  font-weight: 600;
}
.cota_scroll_title {
  font-size: auto;
  margin-block-start: 1.5vw;
  margin-left: 0.2vw;
  font-size: calc(2vw - 4.3px);
  font-weight: 600;
  line-height: 120%;
}
.cota_scroll_stat {
  font-size: auto;
  margin-left: 0.2vw;
  margin-block-start: 3px;
  margin-block-end: 3px;
  line-height: 110%;
  font-size: calc(1.8vw - 4px);
}
.cota_scroll_modal_content {
  color: var(--jet);
  height: calc(100% - 80px);
  overflow-y: auto;
  text-align: left;
  font-family: 'Century Gothic';
  font-size: 15px;
  font-weight: 300;
  margin-block-start: 5px;
  margin-block-end: 5px;
  unicode-bidi: bidi-override; /* Add this line to ensure text direction remains left-to-right */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.cota_scroll_modal_content_dark {
  color: var(--ivory);
}
.cota_scroll_modal_paragraph {
  margin-block-start: 8px;
  margin-block-end: 8px;
}
.cota_scroll_modal_divider {
  width: 100%;
  background-color: var(--jet);
  height: 2px;
}
.cota_scroll_modal_divider_dark {
  background-color: var(--onyx);
}
.cota_scroll_modal_level {
  padding-top: 8px;
  left: 0px;
  width: 100%;
  height: 74px;
  font-family: 'Century Gothic';
  font-size: 15px;
  font-weight: 600;
}
.cota_scroll_modal_footer {
  padding-top: 6px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 74px;
  font-family: 'Century Gothic';
  font-size: 15px;
  font-weight: 600;
}
.cota_notes_modal_notes {
  width: calc(100% - 50px);
  background-color: var(--ivory);
  border: 2px solid var(--jet);
  border-radius: 0px;
  height: 2px;
  padding-top: 6px;
  position: absolute;
  bottom: 15px;
  left: 15px;
  height: calc(100% - 90px);
  font-family: 'Century Gothic';
  font-size: 15px;
  font-weight: 300;
  resize: none;
}
.cota_notes_modal_notes_dark {
  color: var(--ivory);
  background-color: var(--jet);
  border: 2px solid var(--onyx);
}

/* PART 5 INTERACTIVE ELEMENTS (OVERLAYS) */
.cota_stats_path_subpath_enabled {
  border-bottom-right-radius: 0px;
  outline: solid var(--cinnabar);
  outline-width: clamp(1px, 0.8vw, 9px);
  outline-offset: clamp(-9px, -0.7vw, 1px);
}
.cota_stats_level_warning {
  bottom: -30%;
  left: 21px;
  width: 90%;
  height: 30%;
  z-index: 1;
  justify-content: flex-start;
  font-size: clamp(1px, 1.3vw, 17px);
}
.cota_outline_subpath {
  outline: solid var(--cinnabar);
  outline-width: clamp(1px, 0.8vw, 9px);
  outline-offset: -0.7vw;
  outline-offset: clamp(-9px, -0.7vw, 1px);
}

/* Layout M - Mobile (width < 500px) */
@media only screen and (max-width: 500px) {
}

/* Layout D - Desktop (width > 500px and specific aspect ratios) */
@media only screen and (min-width: 962px) and (min-height: 762px) and (min-aspect-ratio: 1510/962) {
  /* PART 1 LAYOUT */
  .cota_character_sheet {
    display: flex;
    position: fixed;
  }
  .cota_character_sheet_dark {
    display: flex;
    position: fixed;
  }
  .cota_character_sheet_left {
    width: 50%;
  }
  .cota_character_sheet_right {
    width: 50%;
  }
  .cota_character_sheet_left_whole {
    padding-right: 5px;
    height: calc(100% - 20px);
  }
  .cota_character_sheet_right_up {
    height: calc(40% - 15px);
    padding: 10px;
    padding-left: 5px;
    padding-bottom: 5px;
  }
  .cota_character_sheet_right_down {
    height: calc(60% - 15px);
    padding: 10px;
    padding-left: 5px;
    padding-top: 5px;
  }
  .cota_outline {
    outline: 0.7vh solid var(--jet);
    outline-offset: -0.6vh;
  }
  .cota_outline_dark {
    outline: 0.7vh solid var(--ivory);
    outline-offset: -0.6vh;
  }
  .cota_character_page_1 {
    z-index: 1;
    width: 100%;
    height: 100%;
    border-top-right-radius: 0px;
  }
  .cota_character_page_2 {
    width: 100%;
    height: 100%;
    border-top-right-radius: 20px;
  }
  .cota_character_page_3 {
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 0px;
  }

  /* PART 2 STATS */
  .cota_header_container {
    position: relative;
    width: 100%;
    height: 16.5%;
    height: calc(15% - 30px);
  }
  .cota_header {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .cota_img_container {
    position: absolute;
    top: 15%;
    left: 1%;
    width: 19%;
    height: 70%;
  }
  .cota_house_img_container {
    position: absolute;
    top: 15%;
    right: 1%;
    width: 19%;
    height: 70%;
    cursor: pointer;
  }
  .cota_stats_subpath {
    font-size: calc(2.5vh - 5.83px);
    outline: 0.7vh solid var(--jet);
    outline-offset: -0.6vh;
  }
  .cota_stats_subpath_dark {
    font-size: calc(2.5vh - 5.83px);
    outline: 0.7vh solid var(--ivory);
    outline-offset: -0.6vh;
  }
  .cota_stats_subpath_enabled {
    outline: 0.7vh solid var(--cinnabar);
    outline-offset: -0.6vh;
  }
  .cota_stats_area {
    font-size: calc(3vh - 7px);
    outline: 0.7vh solid var(--jet);
    outline-offset: -0.6vh;
  }
  .cota_stats_area_dark {
    font-size: calc(3vh - 7px);
    outline: 0.7vh solid var(--ivory);
    outline-offset: -0.6vh;
  }
  .cota_stats_area:focus {
    outline: 0.7vh solid var(--gold);
    outline-offset: -0.6vh;
  }
  .cota_stats_identity {
    outline: 0.7vh solid var(--jet);
    outline-offset: -0.6vh;
  }
  .cota_stats_identity_dark {
    outline: 0.7vh solid var(--ivory);
    outline-offset: -0.6vh;
  }
  .cota_stats_character_name {
    font-size: calc(3vh - 7px);
  }
  .cota_stats_character_title {
    font-size: calc(3vh - 7px);
  }
  .cota_stats_label {
    font-size: calc(2.5vh - 5.83px);
  }
  .cota_stats_label_dark {
    font-size: calc(2.5vh - 5.83px);
  }
  .cota_stats_might_label {
    height: 3vh;
  }
  .cota_stats_might_1 {
    top: 21.5%;
  }
  .cota_stats_might_2 {
    top: 27.2%;
  }
  .cota_stats_might_3 {
    top: 38.9%;
  }
  .cota_stats_might_4 {
    top: 44.8%;
  }
  .cota_stats_might_5 {
    top: 39%;
  }
  .cota_stats_might_6 {
    top: 27.2%;
  }
  .cota_stats_divination_label {
    height: 3vh;
  }
  .cota_stats_divination_1 {
    top: 21.6%;
  }
  .cota_stats_divination_2 {
    top: 27.3%;
  }
  .cota_stats_divination_3 {
    top: 39.2%;
  }
  .cota_stats_divination_4 {
    top: 44.9%;
  }
  .cota_stats_divination_5 {
    top: 39.1%;
  }
  .cota_stats_divination_6 {
    top: 27.3%;
  }
  .cota_stats_agility_label {
    height: 3vh;
  }
  .cota_stats_agility_1 {
    bottom: 45.2%;
  }
  .cota_stats_agility_2 {
    bottom: 39.5%;
  }
  .cota_stats_agility_3 {
    bottom: 27.8%;
  }
  .cota_stats_agility_4 {
    bottom: 21.9%;
  }
  .cota_stats_agility_5 {
    bottom: 27.8%;
  }
  .cota_stats_agility_6 {
    bottom: 39.4%;
  }
  .cota_stats_intuition_label {
    height: 3vh;
  }
  .cota_stats_intuition_1 {
    bottom: 45.1%;
  }
  .cota_stats_intuition_2 {
    bottom: 39.3%;
  }
  .cota_stats_intuition_3 {
    bottom: 27.5%;
  }
  .cota_stats_intuition_4 {
    bottom: 21.8%;
  }
  .cota_stats_intuition_5 {
    bottom: 27.5%;
  }
  .cota_stats_intuition_6 {
    bottom: 39.4%;
  }

  /* PART 3 RP ABILITIES */
  .cota_rp_container {
    display: block;
    flex-direction: column;
    height: 100%;
    font-family: 'Century Gothic';
    font-size: calc(2vh - 5px);
  }
  .cota_rp_title {
    height: 22%;
  }
  .cota_rp_explainer {
    line-height: 105%;
    height: calc(58% - 15px);
  }
  .cota_rp_actions {
    height: 25%;
  }
  .cota_potion_name {
    height: calc(58% - 15px);
  }

  /* PART 4 SCROLLS */
  .cota_scroll_select {
    font-size: calc(2vh - 4px);
  }
  .cota_scroll_title {
    margin-block-start: 10px;
    margin-block-end: 5px;
    line-height: 100%;
    font-size: calc(2vh - 5px);
  }
  .cota_scroll_stat {
    margin-block-start: 3px;
    margin-block-end: 3px;
    line-height: 103%;
    font-size: calc(1.5vh - 3.75px);
  }

  /* PART 5 INTERACTIVE ELEMENTS (OVERLAYS) */
  .cota_stats_path_subpath_enabled {
    border-bottom-right-radius: 0px;
    outline: 0.7vh solid var(--cinnabar);
    outline-offset: -0.6vh;
  }
  .cota_stats_level_warning {

    font-size: calc(1.5vh - 3.75px);
  }
  .cota_outline_subpath {
    outline: 0.7vh solid var(--cinnabar);
    outline-offset: -0.6vh;
  }
  
}

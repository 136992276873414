.page_container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: var(--ivory);
}

.page_top {
  background-color: var(--cinnabar);
  width: calc(100% - 40px);
  height: 80px;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 0 20px 0 20px;
}

.page_top_dark {
  background-color: var(--cinnabar);
  width: calc(100% - 40px);
  height: 80px;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 0 20px 0 20px;

  background-color: var(--onyx);
}

.page_content {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  padding-top: 80px;
  overflow-y: hidden;
  overflow-x: auto;
}

html {
  font-family: 'Century Gothic';
}
